<footer class="footer-wrap text-white" *ngIf="showFooter">
  <!-- Section: Form -->
  <div class="wrap-subscribe">
    <div class="container">
      <!--Grid row-->
      <div class="row d-flex">
        <!--Grid column-->
        <div class="col-md-6 col-12">
          <h3 class="h3-title">Subscribe to stay updated</h3>
          <p>
            Subscribe to our newsletter to stay updated about the Indian startup ecosystem.<br /> Get key data metrics, funding history, financial performance, valuation insights, <br />and industry trends directly to your inbox, twice a week.

          </p>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-6 col-12">
          <form class="Subscribe-form" [formGroup]="subscribeForm">
            <!-- Email input -->
            <div class="form-outline d-flex form-white">
              <input
                type="text"
                id="email"
                name="email"
                class="form-control"
                formControlName="email"
                placeholder="Enter Email"
              />
              <div
                *ngIf="
                  subscribeForm.controls['email'].touched &&
                  subscribeForm.controls['email'].invalid &&
                  subscribeForm.controls['email'].dirty
                "
                class="invalid-feedback"
                role="alert"
              >
                <span *ngIf="subscribeForm?.controls['email']?.errors?.required"
                  >This field is required.</span
                >
                <span *ngIf="subscribeForm?.controls['email']?.errors?.url"
                  >Required a valid URL.</span
                >
                <span *ngIf="subscribeForm?.controls['email']?.errors?.email"
                  >Required a valid Email.</span
                >
              </div>
              <button
                type="submit"
                (click)="subscribe()"
                class="btn btn-outline-ligh btn-blue-bg"
              >
                Subscribe
              </button>
            </div>
            <p>You can update your preferences and unsubscribe at any time.</p>
          </form>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
  </div>
  <!-- Section: Form -->
  <!-- Section: Links -->
  <div class="section-menu-links">
    <div class="container">
      <!--Grid row-->
      <div class="row">
        <!--Grid column-->
        <div class="col-lg-3 col-6 mb-4 mb-md-0">
          <h5 class="sub-heading">Company</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a [routerLink]="'/about-us'" class="link-text">About us</a>
            </li>
            <!-- <li>
                            <a  class="link-text">News</a>
                        </li> -->
            <li>
              <a [routerLink]="'/prices'" class="link-text">TheKredible Pro</a>
            </li>
            <li>
              <a href="/blogs" class="link-text">Blogs</a>
            </li>
            <li>
              <a [routerLink]="'/contact-us'" class="link-text">Contact us</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-3 col-6 mb-4 mb-md-0">
          <h5 class="sub-heading">Work with us</h5>

          <ul class="list-unstyled mb-0">
            <!-- <li>
              <a [routerLink]="'/partners'" class="link-text">Partners</a>
            </li> -->
            <li>
              <a [routerLink]="'/career'" class="link-text">Career</a>
            </li>
            <li>
              <a [routerLink]="'/team'" class="link-text">Team</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-3 col-6 mb-4 mb-md-0">
          <h5 class="sub-heading">Legal</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a [routerLink]="'/privacy-policy'" class="link-text"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a [routerLink]="'/terms-and-conditions'" class="link-text"
                >Terms of use</a
              >
            </li>
            <li>
              <a [routerLink]="'/refund-policy'" class="link-text"
                >Refund & Cancellation Policy</a
              >
            </li>

            <!-- <li>
              <a [routerLink]="'/monetizing-gateway'" class="link-text">Monetizing Gateway</a>
            </li> -->
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-3 col-6 mb-4 mb-md-0">
          <h5 class="sub-heading">Follow us</h5>

          <div class="d-flex sociallinks">
            <a
              href="https://www.linkedin.com/company/76499394/"
              target="_blank"
              class="link-icon"
            >
              <i class="fab fa-linkedin-in"></i>
            </a>
            <a
              href="https://twitter.com/TheKredible"
              target="_blank"
              class="link-icon"
            >
              <!-- <i class="fab fa-twitter"></i> -->
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.4509 4.83401L12.6877 0H10.434L7.40291 3.48587L4.71441 0.0271486H0.388662L5.149 6.07812L0 12H2.17023L6.19499 7.4077L9.78608 11.9727H14L8.4509 4.83401ZM2.73788 1.1786H4.26675L11.6504 10.8214H10.3452L2.73788 1.1786Z" fill="white"/>
              </svg>
            </a>
            <a
              href="https://www.facebook.com/thekredible"
              target="_blank"
              class="link-icon"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
            <a
              href="https://www.instagram.com/thekredible/"
              target="_blank"
              class="link-icon"
            >
              <i class="fab fa-instagram-square"></i>
            </a>
          </div>

          <h5 class="sub-heading">Reach us</h5>
          <ul class="list-unstyled mb-0">
            <li>
              <a ng-href="tel:+917985428953" class="link-text">+917985428953</a>
            </li>
            <li>
              <a ng-href="mailto:hello@kredible.com" class="link-text"
                >contact@thekredible.in </a
              >
            </li>
          </ul>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
  </div>
  <!-- Section: Links -->

  <!-- Copyright -->
  <div class="section-copyright">
    <div class="container">
      <p>© Copyrights TheKredible {{ currentYear }} | All Rights Reserved.</p>
    </div>
  </div>
  <!-- Copyright -->

  <div class="bottom-btn-wrap mobile-show" *ngIf="isCompanyDetailsHeader">
    <!-- <p-confirmPopup></p-confirmPopup> -->
    <!-- <a href="#" class="download"><img src="assets/images/icons/clouddownload.svg" class="icon-img" /></a> -->
    <!-- <button *ngIf="isPremium || isAdvance" (click)="compareCompany()" [ngClass]="{'added-in-compare-list btn-black-bg': isAddedInCompareList}"
      class="btn btn-outline-light btn-grey-bg">{{ isAddedInCompareList? "Added":"Compare"}}</button> -->
    <!-- *ngIf="isFree" (click)="confirm($event)" -->
    <!-- <button *ngIf="isPremium || isAdvance" (click)="compareCompany()" [ngClass]="{'added-in-compare-list btn-black-bg': isAddedInCompareList}"
      class="btn btn-outline-light btn-grey-bg">{{ isAddedInCompareList? "Added":"Compare"}}</button> -->
    <!-- <button *ngIf="isFree" (click)="confirm($event)"
      class="btn btn-outline-light btn-grey-bg">Compare</button> -->
    <!-- <button (click)="compareCompany()" [ngClass]="{'added-in-compare-list btn-black-bg': isAddedInCompareList}"
    class="btn btn-outline-light btn-grey-bg">{{ isAddedInCompareList? "Added":"Compare"}}</button> -->
    <button
      (click)="followCompany()"
      [ngClass]="{ 'added-in-follow-list btn-black-bg': isAddedInFollowList }"
      class="btn btn-outline-light btn-blue-bg"
    >
      {{ isAddedInFollowList ? "Unfollow" : "Follow" }}
    </button>
  </div>
</footer>
